.wrapper {
  position: relative;
  overflow: hidden; /* Ensure shadows are contained within the wrapper */
}

.vertical_wrapper {
  position: relative;
  overflow: hidden; /* Ensure shadows are contained within the wrapper */
  height: 100%;
}

.container {
  overflow-x: auto;
  position: relative;
}

.vertical_container {
  overflow-y: auto;
  position: relative;
}

.content {
  display: inline-block;
  width: 100%;
  white-space: nowrap; /* Ensure content is scrollable horizontally */
}

.shadow {
  position: absolute; /* Position shadows relative to the wrapper */
  top: 0;
  bottom: 0;
  width: 20px;
  pointer-events: none; /* Allow clicks to pass through */
  display: none; /* Initially hidden */
  z-index: 2;
}

.shadow.visible {
  display: block;
}

.left {
  left: 0;
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.3);
}

.right {
  right: 0;
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.3);
}

.top {
  top: 0;
  bottom: unset;
  height: 20px;
  width: 100%; /* Stretch shadow across the width of the element */
  box-shadow: inset 0 15px 10px -10px rgba(0, 0, 0, 0.15);
}

.bottom {
  bottom: 0;
  top: unset;
  height: 20px;
  width: 100%; /* Stretch shadow across the width of the element */
  box-shadow: inset 0 -15px 10px -10px rgba(0, 0, 0, 0.15);
}
